import { Component } from '@angular/core';

@Component({
  selector: 'my-hero-detail',
  templateUrl: './about.component.html'
})
export class AboutComponent {


}
